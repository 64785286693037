import useGet from "api/useGet";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ContentsSection, MainVideo, NumberBox, PageSizing, PageTitle } from "component/app/items";
import socketio from "socket.io-client"
import Echo from "laravel-echo";

const Cafe = (props) => {
    const navigate = useNavigate();

    const [type,setType] = useState(0);
    const [nickName,setNickName] = useState(null);
    const [number,setNumber] = useState(null);

    const [dataUpdate,setDataUpdate] = useState(1);

    const monitorApi = useGet({
        url:dataUpdate ? `/cafe/monitor?update=${dataUpdate}` : null,
        loginType:"login"
    });
    const monitorData = monitorApi?.data;

    useEffect(() => {
        if(monitorData){
            setNumber(monitorData?.car_number ? monitorData?.car_number : null);
            setNickName(monitorData?.nickname ? monitorData?.nickname : null);
            setType(monitorData?.type ? monitorData?.type : null);
        }
    }, [monitorApi]);

    useEffect(() => {
        if(!window.Echo){
            window.Echo = new Echo({
                host: process.env.REACT_APP_SOCKET_URL, 
                broadcaster: "socket.io",
                client: socketio
            });
        }

        window.Echo.channel("laravel_database_complete").listen("CompletePushed", (e) => {
            if(e.type === "detailing"){
                setDataUpdate(dataUpdate + 1);
                setTimeout(() => {
                    setDataUpdate(null);
                }, 100);
            }
        });
    }, []);

    return (
        <PageSizing addClass="headerPage">
            <ContentsSection addClass={type !== "end" ? "fullPage" : ""}>
                {type !== "end" ?
                    <MainVideo src={"/assets/images/cafe/autonova.m4v"}/>
                : type === "end" ?
                    <NumberBox
                        bg={null}
                        number={number}
                    />
                :""
                }
                {type === "end" ?
                <PageTitle contents={`${nickName||"고객"} 님 세차가 완료되었습니다<br/><span class='drTextColor'>디테일링 팩토리</span>로 이동해 주세요`}/>
                :""}
            </ContentsSection>
        </PageSizing>
    );
};

export default Cafe;